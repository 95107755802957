import Vue from "vue";

export default Vue.extend({
  name: "ConfiguracionComponent",
  props: {
    cajero: {
      type: Array,
      required: true,
    },
  },
});
